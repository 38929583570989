import React from 'react';
import './index.sass';

const OfferItem = (props) => {
    const {
        logo,
        title,
        description
    } = props;

    return (
        <div className="OfferItem">
            <div className="OfferItem__Image">
                <img src={logo} alt={title}/>
            </div>
            <h6 className='OfferItem__Title'>{title}</h6>
            <p className='OfferItem__Descr'>{description}</p>
        </div>
    );
};

export default OfferItem;