import React from 'react';
import './index.sass';
import { YMaps, Map, Placemark  } from 'react-yandex-maps';
import {ReactComponent as PhoneIcon} from './icons/phone.svg';
import {ReactComponent as EmailIcon} from './icons/mail.svg';
import {ReactComponent as AdressIcon} from './icons/address.svg';

const Contacts = () => {
    const coordinates = [54.895807, 83.105204]
    return (
        <div className="Contacts">
            <div className="container Contacts__Container">
                <div className="Contacts__Inner">
                    <div className="Contacts__Box">
                        <div className="Contacts__Content">
                            <h2 className="Contacts__Title">
                                Контакты
                            </h2>
                            <div className="Contacts__Info">
                                <div className="Contacts__Row">
                                    <PhoneIcon
                                        className={"Contacts__Icon"}
                                    />
                                    <a className="Contacts__Text" href="tel:79153554802">
                                        +7 915 355 4802
                                    </a>
                                </div>
                                <div className="Contacts__Row">
                                    <AdressIcon
                                        className={"Contacts__Icon Contacts__Icon--Disabled"}
                                    />
                                    <span className="Contacts__Text">
                                        630055, г.Новосибирск, ул.Зеленая горка, д.1, офис 309
                                    </span>
                                </div>
                                <div className="Contacts__Row">
                                    <EmailIcon
                                        className={"Contacts__Icon"}
                                    />
                                    <a className="Contacts__Text" href="mailto:info@smartsp.ru">
                                        info@smartsp.ru
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="Contacts__Map">
                            <YMaps>
                                <Map defaultState={{
                                    center: coordinates,
                                    zoom: 16,
                                }}
                                 width='100%'
                                 height='100%'
                                >
                                    <Placemark geometry={coordinates} />
                                </Map>
                            </YMaps>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;