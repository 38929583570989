import React from 'react';
import './index.sass';
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation} from 'swiper';
import ProjectSlide from "./Project-Slide";
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss';
import CB from './images/CB.png';
import CRP from './images/CPR.png';
import DOM from './images/DOM.png';
import DS from './images/DS.png';
import RKS from './images/RKS.png';

SwiperCore.use([Navigation]);

const data = [
    {
        title: 'Краудсорсинг-платформа',
        description: 'Платформа для вовлечения сотрудников в процессы улучшения продуктов и сервисов организации',
        customer: 'ЦБ РФ',
        technologies: ['JAVA', 'React'],
        payload: '10 000',
        image: CB
    },
    {
        title: 'Цифровой профиль региона',
        description: 'Информационная система для первых лиц субъектов РФ, представителей управленческого звена и специалистов',
        customer: 'АО "Айкумен - ИБС" ПАО "Ростелеком"',
        technologies: ['JAVA', 'React'],
        payload: '10 000',
        image: CRP
    },
    {
        title: 'Консультационный центр ДОМ.РФ',
        description: 'Единая система для консультирования пользователей по вопросам ипотечного кредитования',
        customer: 'ДОМ.РФ',
        technologies: ['JAVA', 'React'],
        payload: '10 000',
        image: DOM
    },
    {
        title: 'Академия детства',
        description: 'Иновационная образовательная платформа для диагностики психофизического состояния детей',
        customer: 'Инвест Компания',
        technologies: ['JAVA', 'React'],
        payload: '10 000',
        image: DS
    },
    {
        title: 'Система хранения аналитических данных',
        description: 'Система информационной поддержки руководства Госкорпорации "Роскосмос"',
        customer: 'ГК "Роскосмос"',
        technologies: ['JAVA', 'React'],
        payload: '10 000',
        image: RKS
    },
]

const Projects = () => {
    return (
        <div className="Projects">
            <div className="container">
                <h2 className="Projects__Title">Проекты</h2>

                <div className="Projects__Box">
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation
                        autoHeight={false}
                        className="Projects-Slider"
                    >
                        {
                            data.map((slide, index) => {
                                return (
                                    <SwiperSlide key={index.toString()}>
                                        <ProjectSlide slide={slide}/>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default Projects;