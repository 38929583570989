import React, {useState, useEffect} from 'react';
import './index.sass';
import ButtonLink from "./ButtonLink";
import Logo from './logo.svg'
import classNames from 'classnames';
import debounce from "lodash-es/debounce";


const LABELS = [
  {
    label: 'Главная',
    anchor: 'intro'
  },
  {
    label: 'Мы предлагаем',
    anchor: 'offer'
  },
  {
    label: 'Наши возможности',
    anchor: 'ability'
  },
  {
    label: 'Проекты',
    anchor: 'projects'
  },
  {
    label: 'Контакты',
    anchor: 'contacts'
  },
];

const Header = ({handleScroll, handleToggleMenu}) => {

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', debounce(function () {
     setIsActive(window.pageYOffset > 10)
    }, 10))
  }, [])


  return (
    <div className={classNames({
      'Header': true,
      'Header--Active': isActive
    })}>
      <div className="Header__Container container container--slim">
        <a className="Header__Logo" href="/">
          <img src={Logo} alt='SmartSP' className='Smart SP'/>
        </a>

        <nav className='Header__Nav'>
          {
            LABELS.map(({label, anchor}) => {
              return (
                  <ButtonLink
                      label={label}
                      anchor={anchor}
                      key={label}
                      handleScroll={handleScroll}
                  />
              )
            })
          }
        </nav>

        <div
            onClick={handleToggleMenu}
            className="Header__Toggler"
        >
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default Header;