import React, {useEffect, useRef, useState} from 'react';
import './index.sass';
import Blob from '../Blob'

import Logo1 from './icons/ic_1.svg';
import Logo2 from './icons/ic_2.svg';
import Logo3 from './icons/ic_3.svg';
import Logo4 from './icons/ic_4.svg';
import Logo5 from './icons/ic_5.svg';
import Logo6 from './icons/ic_6.svg';
import Logo7 from './icons/ic_7.svg';
import Logo8 from './icons/ic_8.svg';
import Logo9 from './icons/ic_9.svg';
import { Swiper, SwiperSlide,  } from 'swiper/react'
import 'swiper/components/pagination/pagination.scss';
import SwiperCore, {Navigation, Pagination} from "swiper";
import debounce from "lodash-es/debounce";

const competences = [
    {
        logo: Logo1,
        title: 'Торговые площадки',
    },
    {
        logo: Logo2,
        title: 'Системы управления бизнесс-процессами',
    },
    {
        logo: Logo3,
        title: 'Учетные системы различных профилей',
    },
    {
        logo: Logo4,
        title: 'Системы электронного документооборота',
    },
    {
        logo: Logo1,
        title: 'Торговые площадки',
    },
    {
        logo: Logo5,
        title: 'Системы отчетности и корпоративные хранилища данных',
    },
    {
        logo: Logo6,
        title: 'Системы интерактивного взаимодействия на основе веб-технологий ',
    },
    {
        logo: Logo7,
        title: 'Мобильные приложения',
    },
    {
        logo: Logo8,
        title: 'Облачные сервисы',
    },
    {
        logo: Logo9,
        title: 'Системы персонализированного маркетинга',
    },
]
SwiperCore.use([Pagination]);
const MOBILE_BREAK = 768;

const Competence = () => {

    const myCompetence = useRef(null);
    const [isMobile, setIsMobile] = useState(false);


    useEffect(() => {
        setIsMobile(_ => window.innerWidth < MOBILE_BREAK)
        window.addEventListener('resize', debounce(function () {
            setIsMobile(_ => window.innerWidth < MOBILE_BREAK)
        }, 100))
    }, [])

    return (
        <div className="Competence" ref={myCompetence}>
                <div className="container container--slim Competence__Container">
                    <div className="Competence__Left">
                        <h2 className="Competence__Title">Наши компетенции</h2>
                        <p className="Competence__Text">Мы обладаем широким опытом в различных областях и предлагаем профессиональное решение любой
                            задачи</p>
                    </div>
                    <div className="Competence__Right">
                        <div className="Competence__Area">

                            <div className="Competence__Area-Desktop" id='competence_area_desktop'>
                                <div className="Competence__Area-Row">
                                    {
                                        competences.map(({title, logo}, index) => {
                                            if(index % 2 === 0){
                                                return (
                                                    <div className="Competence__Item" key={index.toString()}>
                                                        <div className="Competence__Item-Inner">
                                                            <img
                                                                src={logo}
                                                                alt={title}
                                                                className="Competence__Item-Logo"
                                                                autoHeight="true"
                                                            />
                                                            <p className="Competence__Item-Title">
                                                                {title}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            else{
                                                return null;
                                            }
                                        })
                                    }
                                </div>
                                <div className="Competence__Area-Row">
                                    {
                                        competences.map(({title, logo}, index) => {
                                            if(index % 2 === 1){
                                                return (
                                                    <div className="Competence__Item" key={index.toString()}>
                                                        <div className="Competence__Item-Inner">
                                                            <img
                                                                src={logo}
                                                                alt={title}
                                                                className="Competence__Item-Logo"
                                                            />
                                                            <p className="Competence__Item-Title">
                                                                {title}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            else{
                                                return null;
                                            }
                                        })
                                    }
                                </div>
                            </div>


                            {/*MOBILE*/}
                            <div className="Competence__Area-Mobile">
                                {
                                    isMobile &&
                                        <Swiper
                                            slidesPerView={1}
                                            pagination={{ clickable: true }}
                                            className="Competence-Slider"
                                        >
                                            {
                                                competences.map(({title, logo}, index) => {
                                                    return (
                                                        <SwiperSlide key={index.toString()}>
                                                            <div className="Competence__Item" key={index.toString()}>
                                                                <div className="Competence__Item-Inner">
                                                                    <img
                                                                        src={logo}
                                                                        alt={title}
                                                                        className="Competence__Item-Logo"
                                                                    />
                                                                    <p className="Competence__Item-Title">
                                                                        {title}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    )
                                                })
                                            }
                                        </Swiper>
                                }
                            </div>
                        </div>
                        <div className="Competence__Blob">
                            <Blob/>
                        </div>

                    </div>
                </div>
        </div>
    );
};

export default Competence;