import React from 'react';
import './index.sass';

const ButtonLink = ({label, anchor, handleScroll}) => {


  // const handleScroll = (anchor) => {
  //   console.log('scroll to', anchor)
  // };

  return (
    <a
      className='ButtonLink'
      href={anchor}
      onClick={(e) => handleScroll(anchor, e)}
    >
      {label}
    </a>
  )
}

export default ButtonLink;