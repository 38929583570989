import React from 'react';
import './index.sass';
import Logo from './mainPic.svg'
import Blob from "../Blob";

const Intro = () => {
    return (
        <div className="Intro" >
            <div className="Intro__Container container container--slim">
                <div className="Intro__Content">
                    <h1 className="Intro__Title">
                        Информационные системы любой сложности
                    </h1>
                    <p className="Intro__Text">
                        Помогаем воплощать высокотехнологичные идеи в повседневную практику
                    </p>
                    <a href="mailto:info@smartsp.ru" className="Intro__Btn">
                        Напишите нам
                    </a>
                </div>
                <div className="Intro__Image">
                    <img src={Logo} alt='Информационные системы любой сложности'/>

                    <div className="Intro__Blob">
                        <Blob/>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Intro;