import React from 'react';
import './index.sass';
import classNames from 'classnames';

import logo1_2x from './logos/logo1@2x.png'
import logo2 from './logos/logo2.png'
import logo2_2x from './logos/logo2@2x.png'
import logo3 from './logos/logo3.png'
import logo3_2 from './logos/logo3@2x.png'
import logo4 from './logos/logo4.png'
import logo4_2 from './logos/logo4@2x.png'
import logo5_2 from './logos/logo5@2x.png'
import logo6 from './logos/logo6.png'
import logo6_2 from './logos/logo6@2x.png'
import logo7_2 from './logos/logo7@2x.png'
import logo8 from './logos/logo8.png'
import logo8_2 from './logos/logo8@2x.png'
import logo9_2 from './logos/logo-9@2x.png'
import logo10_2 from './logos/logo-10@2x.png'
import logo11 from './logos/logo11.png'
import logo11_2 from './logos/logo-11@2x.png'
import logo12_2 from './logos/logo-12@2x.png'
import logo13 from './logos/logo13.png'
import logo13_2 from './logos/logo13@2x.png'
import logo14 from './logos/logo14.png'
import logo14_2 from './logos/logo14@2x.png'
import logo15_2 from './logos/logo-15@2x.png'
import logo16 from './logos/logo16.png'
import logo16_2 from './logos/logo16@2x.png'
import logo17_2 from './logos/logo-17@2x.png'
import logo18 from './logos/logo18.png'
import logo18_2 from './logos/logo18@2x.png'

const logos = [
    { icon: [undefined, logo1_2x], alt: 'kubernetes'},
    { icon: [logo2, logo2_2x], alt: 'elk'},
    { icon: [logo3, logo3_2], alt: 'zabbix'},
    { icon: [logo4, logo4_2], alt: 'kafka'},
    { icon: [undefined, logo5_2], alt: 'redis'},
    { icon: [logo6, logo6_2], alt: 'java'},
    { icon: [undefined, logo7_2], alt: 'python'},
    { icon: [logo8, logo8_2], alt: 'node'},
    { icon: [undefined, logo9_2], alt: 'angular'},
    { icon: [undefined, logo10_2], alt: 'react'},
    { icon: [logo11, logo11_2], alt: 'asp.net'},
    { icon: [undefined, logo12_2], alt: '.net'},
    { icon: [logo13, logo13_2], alt: 'typescript'},
    { icon: [logo14, logo14_2], alt: 'oracle'},
    { icon: [undefined, logo15_2], alt: 'sql server'},
    { icon: [logo16, logo16_2], alt: 'postgreSQL'},
    { icon: [undefined, logo17_2], alt: 'mongoDB'},
    { icon: [logo18, logo18_2], alt: 'mysql'},
];


const Technologies = ({isActive}) => {

    return (
        <div className={classNames({
            'Technologies': true,
            'Technologies--Active': isActive
        })}>
            <div className="container">
                <div className="Technologies__Box">
                    {
                        logos.map((item, index) => {
                            return (
                                <div className="Technologies__Col" key={index.toString()}>
                                    <picture>
                                        <img
                                            srcSet={item.icon[1] && item.icon[1]}
                                            src={item.icon[0] && item.icon[0]}
                                            alt={item.alt}
                                            className={`Technologies__Icon Technologies__Icon--${index + 1}`}
                                        />
                                    </picture>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default Technologies;