import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

/*
  Fonts
*/
import './fonts/Montserrat-Regular.ttf'
import './fonts/Montserrat-Medium.ttf'
import './fonts/Montserrat-Bold.ttf'
import './fonts/Montserrat-SemiBold.ttf'

import './fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff'
import './fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-500.woff'
import './fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-600.woff'
import './fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-700.woff'

import './fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2'
import './fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2'
import './fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-600.woff2'
import './fonts/montserrat-v15-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2'


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

