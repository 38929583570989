import React, {useState} from 'react';
import './index.sass';
import classNames from 'classnames';
import gerb1 from './icons/gerb-1.png';
import gerb2 from './icons/gerb-2.png';
import gerb3 from './icons/gerb-3.png';
import gerb4 from './icons/gerb-4.png';
import gerb5 from './icons/gerb-5.png';
import gerb6 from './icons/gerb-6.png';

const icons = [
    {icon: gerb1, alt: 'Министерство информации Московской области'},
    {icon: gerb2, alt: 'Правительство Московской области'},
    {icon: gerb3, alt: 'ФСО России'},
    {icon: gerb4, alt: 'Госкорпорация ДОМ.РФ'},
    {icon: gerb5, alt: 'Ленэнерго'},
    {icon: gerb6, alt: 'ПАО Ростелеком'},
];


const TrustUs = ({isActive}) => {

    const [active, setActive] = useState(null)

    return (
        <div className={classNames({
            'TrustUs': true,
            'TrustUs--Active': isActive
        })}>
            <div className="container TrustUs__Container">
                <h2 className="TrustUs__Title">
                    Нам доверяют
                </h2>
                <div className="TrustUs__Box">
                    {
                        icons.map((item, index) => {
                            return (
                                <div
                                    className={classNames({
                                        'TrustUs__Col': true,
                                        'TrustUs__Col--Hover': active === index,
                                        'TrustUs__Col--Disabled': (active !== index) && active
                                    })}
                                    key={index.toString()}
                                    onMouseEnter={_ => setActive(index)}
                                    onMouseLeave={_ => setActive(null)}
                                >
                                    <picture>
                                        <img
                                            src={item.icon}
                                            alt={item.alt}
                                            className="TrustUs__Icon"
                                        />
                                    </picture>
                                    <div className="TrustUs__Tooltip">
                                        {item.alt}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default TrustUs;