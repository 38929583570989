import React from 'react';
import './index.sass';

const ProjectSlide = ({slide: {title, description, customer, technologies, payload, image}}) => {
    return (
        <div className="Project-Slide">
            <div className="Project-Slide__Left">
                <div className="Project-Slide__Content">
                    <div className="Project-Slide__Title">
                        {title}
                    </div>
                    <p className="Project-Slide__Description">
                        {description}
                    </p>
                </div>
                <div className="Project-Slide__Info">
                    <div className="Project-Slide__Info-Item">
                        <div className="Project-Slide__Label">Заказчик</div>
                        <div className="Project-Slide__Value">{customer}</div>
                    </div>
                    <div className="Project-Slide__Info-Item">
                        <div className="Project-Slide__Label">Технологии</div>
                        <div className="Project-Slide__Value">
                            {technologies.join(', ')}
                        </div>
                    </div>
                    <div className="Project-Slide__Info-Item">
                        <div className="Project-Slide__Label">Нагрузка</div>
                        <div className="Project-Slide__Value">{payload}</div>
                    </div>
                </div>
            </div>
            <div className="Project-Slide__Right">
                <img src={image} alt=""/>
            </div>
        </div>
    );
};

export default ProjectSlide;