import React from 'react';
import './index.sass';
import {ReactComponent as Map} from './icons/map.svg';
import classNames from "classnames";
import Avatar from './icons/avatar.svg';


const Ability = ({isActive, regions}) => {

    return (
        <div className={classNames({
            'Ability': true,
            'Ability--Active': isActive
        })}>
            <div className="Ability__Container container container--slim">
                <div className="Ability__Content">
                    <h2 className="Ability__Title">Наши возможности</h2>
                    <p className="Ability__Text">Территориальное распределение производственных мощностей по часовым поясам, увеличивает скорость разработки информационных систем не понижая качества</p>
                </div>
                <div className={classNames({
                    'Ability__Map': true,
                    'Ability__Map--1': regions >= 1,
                    'Ability__Map--2': regions >= 2,
                    'Ability__Map--3': regions >= 3,
                    'Ability__Map--4': regions >= 4,
                    'Ability__Map--5': regions >= 5,
                    'Ability__Map--6': regions >= 6,
                    'Ability__Map--7': regions >= 7,
                })}>
                    <Map/>
                    <img src={Avatar} className="Ability__Avatar Ability__Avatar--1" alt="Пользователь-1"/>
                    <img src={Avatar} className="Ability__Avatar Ability__Avatar--2" alt="Пользователь-2"/>
                    <img src={Avatar} className="Ability__Avatar Ability__Avatar--3" alt="Пользователь-3"/>
                    <img src={Avatar} className="Ability__Avatar Ability__Avatar--4" alt="Пользователь-4"/>
                    <img src={Avatar} className="Ability__Avatar Ability__Avatar--5" alt="Пользователь-5"/>
                    <img src={Avatar} className="Ability__Avatar Ability__Avatar--6" alt="Пользователь-6"/>
                    <img src={Avatar} className="Ability__Avatar Ability__Avatar--7" alt="Пользователь-7"/>
                </div>
            </div>
        </div>
    );
};

export default Ability;