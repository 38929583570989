import React from 'react';
import './index.sass';
import OfferItem from "./OfferItem";

import Logo1 from './icons/ic_1.svg'
import Logo2 from './icons/ic_2.svg'
import Logo3 from './icons/ic_3.svg'
import Logo4 from './icons/ic_4.svg'
import Logo5 from './icons/ic_5.svg'
import Logo6 from './icons/ic_6.svg'


export const OFFERS_LIST = [
    {
        logo: Logo1,
        title: 'Консалтинг',
        description: 'Консалтинг по разработке ИТ-стратегии предприятия, внедрению систем управления проектами, процессов ITSM'
    },
    {
        logo: Logo2,
        title: 'Индивидуальная разработка',
        description: 'Индивидуальная разработка программного обеспечения (web, desktop, mobile, office 365)'
    },
    {
        logo: Logo3,
        title: 'Решения на платформе MS',
        description: 'Реализация решений на базе платформ Microsoft (SharePoint, Project Server, Dynamic CRM)'
    },
    {
        logo: Logo4,
        title: 'Современный дизайн',
        description: 'Уникальный стиль приложений. Разработка интерфейсов приложений в соответствии с брендбуком компании'
    },
    {
        logo: Logo5,
        title: 'Дополненная реальность',
        description: 'Разработка презентационных бизнес-приложений с применением технологии дополненной реальности'
    },
    {
        logo: Logo6,
        title: 'Поддержка 24/7',
        description: 'Предоставление услуг по 2-й и 3-й линии поддержки пользователей для систем, разработанных нашей командой'
    }
]


const Offer = () => {
    return (
        <div className="Offer">
            <div className="container container--slim">
                <h2 className='Offer__Title'>Мы предлагаем</h2>
                <div className='Offer__List'>
                    {
                        OFFERS_LIST.map(({logo, title, description}) => <OfferItem logo={logo} title={title} description={description} key={title}/>)
                    }
                </div>
            </div>

        </div>
    );
};

export default Offer;