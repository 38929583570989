import React from 'react';
import BlobIcon from './blob2.svg';
import './index.sass';

const Blob = () => {
    return (
        <img
            src={BlobIcon}
            className="Blob"
            alt="blob"
        />
);
};

export default Blob;