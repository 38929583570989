import React, {useState, useRef, useEffect } from 'react';
import './App.sass';
import Header from "./components/Header";
import Intro from "./components/Intro";
import Offer from "./components/Offer";
import Ability from "./components/Ability";
import Competence from "./components/Competence";
import Technologies from "./components/Technologies";
import Projects from "./components/Projects";
import TrustUs from "./components/TrustUs";
import Contacts from "./components/Contacts";
import Footer from "./components/Footer";
import { Waypoint } from 'react-waypoint';
import classnames from 'classnames';
import debounce from "lodash-es/debounce";
import {isAtTheBottom, isInViewport} from "./utils";
import {throttle} from "lodash-es";

function App() {
    const [activeSection, setActiveSection] = useState([]);

    const handleSetActiveSection = (val) => {
        if(activeSection.includes(val)) return;
        const newArr = [...activeSection, val]
        setActiveSection(newArr);
    };

    const introRef = useRef(null);
    const offerRef = useRef(null);
    const abilityRef = useRef(null);
    const competenceRef = useRef(null);
    const projectsRef = useRef(null);
    const contactsRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const body = document.body;
        isOpen ? body.classList.add('noScroll') : body.classList.remove('noScroll')
    }, [isOpen])

    const handleToggleMenu = () => {
        setIsOpen(isOpen => !isOpen)
    };

    const handleScroll = (ref, e) => {
        e.preventDefault();
        setIsOpen(false)
        let selectedRef = null;
        switch(ref) {
            case 'intro':
                selectedRef = introRef;
                break;
            case 'offer':
                selectedRef = offerRef;
                break;
            case 'ability':
                selectedRef = abilityRef;
                break;
            case 'projects':
                selectedRef = projectsRef;
                break;
            case 'contacts':
                selectedRef = contactsRef;
                break;
            default:
                return null;
        };
        setTimeout(() => {
            window.scrollTo({ behavior: 'smooth', top: selectedRef.current.offsetTop })
        }, 200)
    };


    useEffect(() => {
        const area = document.getElementById('competence_area_desktop');

        const scrollArea = throttle((e, direction) => {
            area.scrollBy({
                top: direction === 'down' ? 140 : -140,
                left: 0,
                behavior: 'smooth'
            })
        }, 200, {trailing:false})

        document.body.addEventListener('wheel', function(e){
            if (isInViewport(area) && !isAtTheBottom(area)) {
                // area.scrollIntoView()
                e.preventDefault();
                e.stopPropagation();
                scrollArea(e, e.deltaY > 0 ? 'down' : 'up')
            }
        }, { passive: false })
    }, []);


    const [abilityRegions, setAbilityRegions] = useState(0)
    const updateRegion = debounce(() => setAbilityRegions(regions => regions + 1), 250)

    useEffect(() => {
        // "Наши возможности", анимация карты при скролле
        document.body.addEventListener('wheel', function(e){
            if(e.deltaY > 0 && offerRef.current.getBoundingClientRect().y < 150)
                updateRegion()
        })
    }, []);

    useEffect(() => {
        // "Наши возможности", анимация карты при скролле на сенсорных устройствах
        var touchStart;
        document.body.addEventListener('touchstart', function (e){
            touchStart = e.touches[0].clientY;
        });

        document.body.addEventListener('touchmove', function (e){
            let touchEnd = e.changedTouches[0].clientY;

            if (touchStart-touchEnd>100 && offerRef.current.getBoundingClientRect().y < 150) {
                updateRegion()
                touchStart = e.changedTouches[0].clientY;
            }
        });
    }, []);



    return (
        <div className={classnames({
            'App': true,
            'App--Menu-Open': isOpen
        })}>
            <Header
                handleScroll={handleScroll}
                handleToggleMenu={handleToggleMenu}
            />
            <div className="App__Section" ref={introRef}>
                <Intro/>
            </div>
            <div className="App__Section" ref={offerRef}>
                <Offer/>
            </div>
            <Waypoint onEnter={_ => handleSetActiveSection('Ability')}>
                <div className="App__Section" ref={abilityRef}>
                    <Ability
                        isActive={activeSection.includes('Ability')}
                        regions={abilityRegions}
                    />
                </div>
            </Waypoint>
            <div className="App__Section" ref={competenceRef}>
                <Competence
                    isActive={activeSection.includes('competence')}
                />
            </div>
            <Waypoint onEnter={_ => handleSetActiveSection('technologies')}>
                <div className="App__Section">
                    <Technologies
                        isActive={activeSection.includes('technologies')}
                    />
                </div>
            </Waypoint>
            <div className="App__Section" ref={projectsRef}>
                <Projects/>
            </div>
            <Waypoint onEnter={_ => handleSetActiveSection('trustUs')}>
                <div className="App__Section">
                    <TrustUs
                        isActive={activeSection.includes('trustUs')}
                    />
                </div>
            </Waypoint>
            <div className="App__Section" ref={contactsRef}>
                <Contacts/>
            </div>
            <Footer/>
        </div>
    );
}

export default App;


