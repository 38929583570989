import React from 'react';
import './index.sass';
import logo from './logo.svg';

const Footer = () => {
    return (
        <div className="Footer">
            <div className="container Footer__Container">
                <div className="Footer__Inner">
                    <div className="Footer__Logo">
                        <img src={logo} alt="Smart SP"/>
                    </div>
                    <span className="Footer__Copyright">
                        &copy;&nbsp;2012-2020 Все права защищены
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Footer;